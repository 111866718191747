.t-item{
    padding: 1.9rem;
    margin-bottom: 10px;
    cursor: grab;
}
.t-content{
    position: relative;
    padding: 30px 20px 20px 60px;
    background-color: var(--background);
    border-radius: 8px;
    border: 2px solid #444;
}
.t-picture-box{
    position: absolute;
    display: block;
    top: -30px;
    left: -30px;
    width: 80px;
    height: 80px;
}
.t-picture{
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
}
.t-text{
    p{
        font-size: 1rem;
        color: var(--font-secondary);
        margin: 0 0 10px;
        font-weight: bold;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        font-family: 'work sans';
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
    }

}
.t-text:hover{
    p{
        -webkit-line-clamp: 100;
    }
}

.t-author-info{
    margin-top: 1rem;
}

.t-author{
    font-family: sans-serif !important;
    font-weight: 700 !important;
    font-style: normal !important;
    font-size: 0.8rem !important;
    font-weight: bold;
    margin: 0px !important;
}
.t-author-firm{
    margin: 0;
    font-size: .8em;
    color: var(--font-secondary);

}
.quote-icon-box{
    position: absolute;
    bottom: -2.4rem;
    right: -2.5rem;
    z-index: -1;
}
.quote-icon{
    font-size: 5rem;
    color: gray;
}
.initials{
    height: 5rem;
    width: 5rem;
    background-color: #a53dff;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 56px;
}

.initials-text{
    margin-bottom: 0rem !important;
}
@media screen and (max-width:768px) {
    .t-content{
        padding: 20px 15px 15px 50px;
    }
}