.client-img{
    max-width: 100px;
    width: 100%;
    border-radius: 20%;
    cursor: grab;
}
.client-block{
    padding: 10px 15px 15px;
    margin-bottom: 15px;
    cursor: grab;


}