.resume-title {
  text-align: left;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 56px;
  color: var(--font-headings);
}
.education {
  @extend .resume-title;
  display: inline-block;
  position: relative;
  font-size: 21px;
  margin: 0 0 30px;
  z-index: 1;
}
.after {
  display: block;
  position: absolute;
  content: "";
  width: 30px;
  background-color: #007ced;
  height: 2px;
  bottom: 0;
}
.before {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  background-color: #353535;
  height: 2px;
  bottom: 0;
}
.education::after {
  @extend .after;
}
.education::before {
  @extend .before;
}
.experience {
  @extend .resume-title;
  display: inline-block;
  position: relative;
  font-size: 21px;
  margin: 0 0 30px;
  z-index: 1;
}

.experience::after {
  @extend .after;
}
.experience::before {
  @extend .before;
}
.container-parent {
  margin-top: 10px;
  margin-bottom: 1.5rem;
  padding: 5rem;
  background: var(--background) !important;
  border-radius: 21px;
  overflow: hidden;
}

.timeline-item {
  position: relative;
  padding: 25px 3px 20px 40px;
  display: block;
}

.timeline-item::before {
  content: "";
  position: absolute;
  display: block;
  height: 100%;
  background-color: black;
  width: 1px;
  left: 15px;
  bottom: 5px;
}
.timeline-item::after {
  content: "";
  position: absolute;
  background-color: black;
  display: block;
  height: 1px;
  width: calc(100% - 1rem);
  left: 15px;
  bottom: 3px;
}

.time-period {
  display: inline-block;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 5px -40px;
  color: black;
  background-color: white;
  padding: 0 10px;
  line-height: 23px;
  border: 2px solid #007ced;
  border-radius: 30px;
}

.item-company {
  display: inline-block;
  font-size: 16px;
  color: var(--font-headings);
  font-family: (--font-family);
  opacity: 0.7;
  font-weight: 700;
  margin-left: 5px;
}
.dsc{
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  position: relative;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #556070;

}
.dsc:hover{
  -webkit-line-clamp: 100;
}
//Certificate Section
.certificate{
  @extend .education;
  margin-top: 1rem;
}
.cert-img-box{
  display: block;
  max-width: 100%;
}
.cert-img{
  display: block;
  max-width: 100%;
}
.cert-img-box{
  display: table-cell;
  width: 120px;
  height: 100%;
  background-color: #444;
  padding: 25px;
  vertical-align: middle;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.cert-content{
  display: table-cell;
  padding: 15px 25px;
  vertical-align: middle;
}
.cert-parent{
  position: relative;
  display: table;
  table-layout: fixed;
  width: 100%;
  border: 2px solid #444;
  border-radius: 8px;
  margin-bottom: 20px;
}

.cert-parent:hover{
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25), 
  0 0 8px rgba(0, 0, 0, 0.25), 
  inset 0 0 8px rgba(255, 255, 255, 0.1), 
  inset 0 0 16px rgba(255, 255, 255, 0.1);
}

.certificate-name{
  color: var(--font-headings);
  font-size: 1rem;
  font-weight: bold;
}
.certificate-id{
  font-size: 18px;
  color: #777;
  font-family: 'work sans';

}
.skills{
  @extend .education;
}
.skill-item{
  margin: 0 !important;
  @extend .item-company;
}
.skill-item-box{
  margin-bottom: 1rem;
}
.skill-per-box{
  // display: flex;
  // justify-content: space-between;
}

.skill-box{
  height: 12rem;
  width: 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}


.heading{
  font-weight: 700;
  margin-bottom: 1rem !important;
  color: var(--font-headings) !important;
}

.tab{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-left: 2rem;

}
.btn{
  border-radius: 3px !important;
  height: 3rem;
  background-color: var(--primary) !important;
  border:none;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: white !important;
  
}
.btn:active{
  background-color: black;
}

.btn-2{
  @extend .btn;
  margin-right: 19rem;
}
.btn-1{
  @extend .btn;
}
.btn-2-ex{
  @extend .btn;
  margin-right: 19rem;
  background-color: gray !important;
  color: black !important;
  height: 2.5rem;
}
.btn-1-ex{
  @extend .btn;
  background-color: gray !important;
  color: black !important;
  height: 2.5rem;

}


// .btn-2:active{
//   background-color: black !important;
// }

.btn-color{
  background-color: white;
}
@media screen and (max-width: 500px) {
  .skill-box{
    height: 8rem;
    margin-left: -1rem;
  }
}
.skill-name{
  color: var(--font-headings);
  font-weight: 600;
  font-family: var(--font-family);
  font-size: large;
  text-align: center;
}
@media screen and (max-width:768px) {
  .container-parent{
    padding: 1rem;
  }
  .cert-img-box{
    padding: 15px;
  }
  .certificate-name{
    font-size: 0.7rem;
  }
  .certificate-id{
    font-size: 0.6rem;
  }
  .btn-2, .btn-2-ex{
    margin-right: 2rem;
  }

}
@media screen and (max-width:768px) {
.tab{
  justify-content: flex-start;
  margin-left: 0 !important;
}

}