.logo {
  height: 3.5rem;
  width: 3.5rem;
  background-color: #a53dff;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 56px;
}
.brooklyn {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  color: var(--bs_bgblack);
  margin-top: 15px;
}

.common-text {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 24px;
  color: #333333;
  padding: 8px !important;
  text-decoration: none;
  cursor: pointer;
}

