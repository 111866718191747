.btn,
.btn:hover{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 12px;
  height: 48px;

  /* Primary/500 */

  background: var(--primary);
  border-radius: 4px;
  border: var(--primary);

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  color: #ffffff;
//   margin-left: 10px;
  cursor: pointer;
}
.btn:active {
  background-color: var(--primary) !important;
}