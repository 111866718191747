.content-row {
  padding: 5rem;
  background: #ffffff;
  box-shadow: 0px 36px 105px rgba(43, 56, 76, 0.1);
  border-radius: 16px;
  // min-width: 35rem;
  z-index: 3;
  position: relative;
}

.img-icon-box {
//   position: relative;
//   display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
