.blog-info {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #87909d;
}

.blog-info {
  font-family: "Work Sans";
  font-style: normal;
  font-size: 15px;
  line-height: 24px;
  font-weight: 600;
  color: #333333;
}

.blog-card-top {
  cursor: grab;
  // border-top-right-radius: 2rem;
  // border-bottom-left-radius: 2rem;
  max-width: 25rem;
  margin-bottom: 3.5rem;
  // display: flex !important;
  // justify-content: space-around;
  // border-radius: 1rem;
}
.blog-body-div {
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0 !important;
  }
}
.img-div{
  padding-left: 0px !important;
}

.blog-img {
  height: 15rem;  
  // border-radius: 1rem;
}
.blog-desc {
  font-weight: 700;
  font-size: 1.5rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.blog-desc:hover {
  -webkit-line-clamp: 5;
}
.btn,
.btn:hover,
.btn:active {
  background-color: var(--primary);
  border: none;
  margin-top: 0.5rem;
  cursor: pointer;
}
