
// .after {
//     display: block;
//     position: absolute;
//     content: "";
//     width: 30px;
//     background-color: #007ced;
//     height: 2px;
//     bottom: 0;
//   }
//   .before {
//     display: block;
//     position: absolute;
//     content: "";
//     width: 100%;
//     background-color: #353535;
//     height: 2px;
//     bottom: 0;
//   }

//   .t-title{
//     font-family: var(--font-family);
//     font-style: normal;
//     font-weight: 600;
//     font-size: 2rem;
//     color: var(--font-headings);
//     display: inline-block;
//     position: relative;
//     margin: 0 0 30px;
//     z-index: 1;
//     text-align: center !important;
// }
//   .t-title::after {
//     @extend .after;
//   }
//   .t-title::before {
//     @extend .before;
//   }

.t-title{
  color: #132238;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 56px;
  margin-bottom: 10px;
}

.slick-prev{
  left: -1px !important;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  z-index: 1;
  color: black !important;
  opacity: 0.5;
}
.slick-next{
  // @extend .slick-prev;
  right: 3px;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  z-index: 1;
  color: black !important;
  opacity: 0.5;
}