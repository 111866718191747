.blog-title {
  color: #132238;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 56px;
  margin-bottom: 10px;
  position: relative;

}

.blog{
    margin-bottom: 2rem;
}

.icon-parent{
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
}

.view-all, .view-all:hover{
  font-size: 1rem;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
  color: #a53dff;

}

// .slick-prev::before{
//   color: black !important;
// }
// .slick-next::before{
//   color: black !important;
// }
// .slick-slide{
//   width: 375px !important;
// }
.slick-prev{
  left: -20px !important;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  z-index: 1;
  color: black !important;
  opacity: 0.3;
}
.slick-next{
  // @extend .slick-prev;
  right: 3px;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  z-index: 1;
  color: black !important;
  opacity: 0.3;
}
@media screen and (max-width: 600px) {
  .slick-slider{
    margin: 0 2rem;
  }
}

.content-container{
  // display: flex;
  // justify-content: center;
  // flex-direction: row;
}