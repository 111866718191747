.wid-box {
    background: var(--white);
    border-radius: 1rem;
    padding: 2rem;
  }
  .wid-box:hover {
    box-shadow: 0px 32px 96px rgba(28, 25, 25, 0.16);
  }
  
  .col-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;
    gap: 5px;
  }